<div class="col-md-12">

    <h2><label>Informations complémentaires</label></h2>

    <label style="font-size: 20px;"> Pièces jointes</label>

    <div style="margin: 20px;">

        <label class="custom-checkbox">
            <input type="checkbox" id="addFileCheckbox" [(ngModel)]="askToUpload" (change)="handleClick(askToUpload)"  >
            <span class="checkmark" [class.checked]="askToUpload"></span>
            J'associe ma déclaration de régulation au dépôt d'un rapport de fin de forage (fichier .pdf, max 10Mo)
        </label>

    </div>

    <div style="margin: 20px;">
        <label class="custom-checkbox">
            <input type="checkbox" id="noFileCheckbox" [(ngModel)]="askNoFile"  (change)="handleNoFileClick(fileUploader)" >
            <span class="checkmark" [class.checked]="askNoFile"></span>
            Je ne dispose pas de documents décrivant les forages existants
        </label>
    </div>

    <p-fileUpload #fileUploader id="fileuploadfinalisationbtn" mode="basic" [hidden]="true" styleClass="btn btn-md height100" [auto]="true" 
             chooseIcon="" uploadIcon="pi-check" (cancel)="onCancelFileUpload()" maxFileSize="10000000"
             name="myFiles[]" customUpload="true" (uploadHandler)="myUploader($event, fileUploader)" 
            (onSelect)="onSelectUpload($event,fileUploader)" accept=".pdf"></p-fileUpload>

    <br/>

      <label style="font-size: 20px;"> Commentaire libre sur la déclaration (max. 1000 caractères) :</label>
    <textarea id="commentaire" name="commentaire" [(ngModel)]="declaration.commentaire" rows="3" style="width:70%; margin-left: 50px; border-radius: 5px;" ></textarea>
  </div>
