<form id="declarationForm" name="declarationForm" novalidate #editForm="ngForm" style="text-align: center;">
  <dup-alert-error></dup-alert-error>
  <div class="row">
    <div class="row col-md-2" style="margin-top: 40px;">
      <div class="col-md-5">
        <ul id="progressbar" style="padding-left: 30px;">
          <li class="active"></li>
          <li *ngIf="!isDeclarantMOA"></li>
          <li></li>
          <li></li>
          <li *ngIf="typeDeclaration?.id == 1800"></li>
        </ul>
      </div>
      <div class="col-md-7">
        <ul id="progressbar2" style="padding-left: 0px;">
          <li class="active">Déclaration</li>
          <li *ngIf="!isDeclarantMOA">Maitre d'Ouvrage</li>
          <li>Ouvrage(s)</li>
          <li *ngIf="typeDeclaration?.id == 1800">Compléments</li>
          <li>Synthèse</li>
        </ul>
      </div>
    </div>
    <div class="col-md-10">
      <div class="saving" [hidden]="!isSaving">
        <i class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
        Sauvegarde en cours...
      </div>

      <fieldset id="first" class="step" [hidden]="isSaving">
        <legend hidden>Déclaration</legend>
        <div style="text-align:left;">
          <dup-declaration [declaration]="declaration" [typeDeclaration]="typeDeclaration" (eau)="eauStatus($event)" (changeOuvrages)="multiplesOuvrages($event)"></dup-declaration>
        </div>
        <div class="col-md-6">
          <input type="button" name="next" class="btn btn-success btn-md float-right" value="Suivant" (click)="next($event)" [disabled]="validateDeclaration()" />
        </div>
      </fieldset>

      <fieldset class="step" [hidden]="isSaving" *ngIf="!isDeclarantMOA">
        <legend hidden>Maitre d'ouvrage</legend>
        <div class="row">
          <h2 style="text-align:left"  class="fs-title col-md-6">Maitre d'ouvrage</h2>
        </div>
        <div style="text-align:left">
          <dup-acteur-form [acteur]="declaration.acteurMoa" [facultatif]="acteursFacultatifs"></dup-acteur-form>
        </div>
          <div class="col-md-6">
            <input type="button" name="previous" class="btn btn-secondary btn-md" value="Précédent" (click)="toIdentite($event)" />
            <input type="button" name="next" class="btn btn-success btn-md float-right" value="Suivant" (click)="toLocalisation($event)" [disabled]="validateMoa()" />
          </div>

      </fieldset>

      <fieldset class="step" [hidden]="isSaving">
        <legend hidden>Ouvrages</legend>
        <div style="text-align:left;">
          <dup-ouvrage [declaration]="declaration" [declarationOuvrages]="declarationOuvrages" [declarant]="declarant" [plusieursOuvrages]="plusieursOuvrages" [isEau]="isEau" [isDomestique]="isDomestique"
          [(isDeclarantProprietaire)]="isDeclarantProprietaire" [acteursFacultatifs]="acteursFacultatifs" [(isDeclarantExploitant)]="isDeclarantExploitant" [(isDeclarantMOE)]="isDeclarantMOE"></dup-ouvrage>
        </div>
        <div class="col-md-6">
          <input type="button" name="previous" class="btn btn-secondary btn-md" value="Précédent" (click)="previous($event)"/>
          <input type="button" name="next" class="btn btn-success btn-md float-right" value="Suivant" (click)="toOuvrages($event)" [disabled]="validateOuvrages() || !validatePerimetre()"/>
        </div>
      </fieldset>

      <fieldset class="step" *ngIf="typeDeclaration?.id == 1800" [hidden]="isSaving">
        <div style="text-align:left;">
          <dup-complement-declaration-form [declaration]="declaration" [rapportAlreadyUploaded]="rapportAlreadyUploaded" (isComplementValid)="complementValidate($event)" (fileDataUpload)="fileDataUpload($event)"></dup-complement-declaration-form>
        </div>
        <div class="col-md-6">
          <input type="button" name="previous" class="btn btn-secondary btn-md" value="Précédent" (click)="previous($event)"/>
          <input type="button" name="next" class="btn btn-success btn-md float-right" value="Suivant" (click)="toFinalPage($event)" [disabled]="!isComplementValid"/>
        </div>
      </fieldset>


      <fieldset id="last" class="step" [hidden]="isSaving">
        <legend hidden>Résumé</legend>
        <span *ngIf="soumissionError" style="color: red;">Une erreur a eu lieu lors de la transmission à la Banque du Sous-Sol, veuillez réessayer ultérieurement ou <a href="https://assistance.brgm.fr/aide/DUPLOS" target="_blank">contactez l'administrateur</a>.</span>
        <div class="view-declaration-box" style="text-align: left;">
          <h4 class="fs-title">Références de la déclaration : </h4>
          <div class="row form-row">
            <label class="col-md-6 form-control-label">Numéro de la déclaration : {{declaration.id}}</label>
            <label class="col-md-6 form-control-label">Nom du projet : {{declaration.nomProjet}}</label>
          </div>
          <div class="row form-row">
            <label class="col-md-6 form-control-label">Type : {{declaration.typeDeclarationId?find(lextypedeclarations,declaration.typeDeclarationId):''}}</label>
            <label class="col-md-6 form-control-label">Statut de la déclaration : {{declaration.statutDeclarationId?find(lexstatutdeclarations,declaration.statutDeclarationId):''}}</label>
          </div>
        </div>
        <div class="view-declaration-box">
          <h4 class="fs-title">Caractéristiques de la déclaration : </h4>
          <div class="row form-row">
              <label class="col-md-6 form-control-label">Déclarant : {{declarant.contact.prenom}} {{declarant.contact.nom}}</label>
              <label class="col-md-6 form-control-label" *ngIf="!isDeclarantMOA">Maitre d'ouvrage : {{declaration.acteurMoa && declaration.acteurMoa.raisonSocialeEtab?declaration.acteurMoa.raisonSocialeEtab:''}}{{declaration.acteurMoa && declaration.acteurMoa.contact && !declaration.acteurMoa.raisonSocialeEtab?declaration.acteurMoa.contact.prenom:''}} {{declaration.acteurMoa && declaration.acteurMoa.contact &&!declaration.acteurMoa.raisonSocialeEtab?declaration.acteurMoa.contact.nom:''}}</label>
              <label class="col-md-6 form-control-label" *ngIf="isDeclarantMOA">Maitre d'ouvrage : {{declarant.raisonSocialeEtab?declarant.raisonSocialeEtab:declarant.contact.prenom}} {{declarant.raisonSocialeEtab?'':declarant.contact.nom}}</label>
          </div>
          <div class="row form-row">
              <label class="col-md-6 form-control-label" *ngIf="declaration.dateDebutTravaux && declaration.dateFinTravaux">Période envisagée des travaux : Du {{declaration.dateDebutTravaux ? (declaration.dateDebutTravaux | date:'dd/MM/yyyy'):''}} au {{declaration.dateFinTravaux ? (declaration.dateFinTravaux | date:'dd/MM/yyyy') : ''}}</label>
          </div>
          <div class="row form-row">
              <label class="col-md-6 form-control-label">Fonction : {{declaration.fonctionId?declaration.fonctionLibelleComplet:''}}</label>
              <label class="col-md-6 form-control-label" *ngIf="declaration.usageId">Usage : {{declaration.usageId?find(lexusages, declaration.usageId):''}}</label>
          </div>
          <div class="row form-row" >
              <label class="col-md-6 form-control-label" *ngIf="declaration.substanceId">Substance : {{declaration.substanceId?declaration.substanceLibelle:''}}</label>
              <label class="col-md-6 form-control-label" *ngIf="declaration.prelevement">Volume : {{declaration.prelevement}} m<sup>3</sup>/an</label>
            </div>
          <div class="row form-row">
            <!--<label class="col-md-6 form-control-label">Concerne une ICPE : {{declaration.typeIcpeId?find(lextypeicpes, declaration.typeIcpeId):'Non'}}</label>-->
            <label class="col-md-6 form-control-label" *ngIf="declaration.relationId">Relation entre les ouvrages : {{find(lexrelations, declaration.relationId)}}</label>
          </div>
        </div>
        <div class="mes-declarations-container">
          <h4 class="fs-title">Ouvrages : </h4>
          <div class="table-responsive">
            <p-table [value]="declarationOuvrages.ouvrages" class="table table-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Nature</th>
                  <th scope="col">Commune</th>
                  <th scope="col">Coordonnées</th>
                  <th scope="col">Acteurs</th>
                  <th scope="col" *ngIf="isDomestique">Prélèvement envisagé (m<sup>3</sup>/an)</th>
                  <th scope="col">Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-ouvrage>
                <tr>
                  <td>
                    <label>{{ouvrage.nomUsuel}}</label>
                  </td>
                  <td>
                    <label>{{ouvrage.natureOuvrageId?ouvrage.natureOuvrageLibelle:''}}</label><br/>
                  </td>
                  <td>
                    <label>{{ouvrage.adresse?ouvrage.adresse.communeNomCom:'...'}}</label>
                  </td>
                  <td>
                    <div *ngIf="ouvrage.srsId">
                      <label>X: {{ouvrage.x}}, Y: {{ouvrage.y}}, Altitude : {{ouvrage.zsaisie?ouvrage.zsaisie:ouvrage.zcalc}} m</label>
                    </div>
                    <div *ngIf="!ouvrage.srsId">
                      <label>...</label>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span *ngIf="!isDeclarantMOE">Maître d'oeuvre : {{ouvrage.acteurMoe?(ouvrage.acteurMoe.raisonSocialeEtab?ouvrage.acteurMoe.raisonSocialeEtab:(ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.prenom:'')):''}} {{ouvrage.acteurMoe?(ouvrage.acteurMoe.raisonSocialeEtab?'':ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.nom:''):''}}</span>
                      <span *ngIf="isDeclarantMOE">Maître d'oeuvre : {{declarant.raisonSocialeEtab?declarant.raisonSocialeEtab:(declarant.contact?declarant.contact.prenom:'')}} {{declarant.raisonSocialeEtab?'':(declarant.contact?declarant.contact.nom:'')}}</span>
                    </div>
                    <div>
                      <span *ngIf="!isDeclarantProprietaire">Propriétaire : {{ouvrage.acteurProprio?(ouvrage.acteurProprio.raisonSocialeEtab?ouvrage.acteurProprio.raisonSocialeEtab:(ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.prenom:'')):''}} {{ouvrage.acteurProprio?(ouvrage.acteurProprio.raisonSocialeEtab?'':ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.nom:''):''}}</span>
                      <span *ngIf="isDeclarantProprietaire">Propriétaire : {{declarant.raisonSocialeEtab?declarant.raisonSocialeEtab:(declarant.contact?declarant.contact.prenom:'')}} {{declarant.raisonSocialeEtab?'':(declarant.contact?declarant.contact.nom:'')}}</span>
                    </div>
                    <div *ngIf="ouvrage.acteurForeur">
                      <span *ngIf="!isDeclarantExploitant">Foreur : {{ouvrage.acteurForeur?(ouvrage.acteurForeur.raisonSocialeEtab?ouvrage.acteurForeur.raisonSocialeEtab:(ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.prenom:'')):''}} {{ouvrage.acteurForeur?(ouvrage.acteurForeur.raisonSocialeEtab?'':ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.nom:''):''}}</span>
                      <span *ngIf="isDeclarantExploitant">Foreur : {{declarant.raisonSocialeEtab?declarant.raisonSocialeEtab:(declarant.contact?declarant.contact.prenom:'')}} {{declarant.raisonSocialeEtab?'':(declarant.contact?declarant.contact.nom:'')}}</span>
                    </div>
                  </td>
                  <td *ngIf="isDomestique"><label>{{ouvrage.prelevement}}</label></td>
                  <td>
                      <button type="button" (click)="openDialog(ouvrage.id)" class="btn btn-primary btn-sm" title="Ouvrir dans DIALOG">
                        <span class="fas fa-ruler-vertical"></span>
                      </button>
                      <button type="button" (click)="uploadFile(ouvrage.id, ouvrage.nomUsuel)" class="btn btn-primary btn-sm" title="Ajouter une pièce jointe">
                        <span class="fas fa-paperclip"></span>
                      </button>
                    </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptyMessage">
                <tr>
                  <td colspan="7">
                    Aucun ouvrage.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="col-md-6">
          <input type="button" name="previous" class="btn btn-secondary btn-md" value="Précédent" (click)="previous($event)" [disabled]="isLoading" />
          <input type="button" name="delete" class="btn btn-danger btn-md" value="Supprimer" (click)="supprimer($event)" [disabled]="isLoading" />
          <input type="button" name="next" class="btn btn-success btn-md" value="Transmettre" (click)="soumettre($event)" [hidden]="declaration.typeDeclarationId == 1800" [disabled]="!isValid || isLoading" [title]="titreSoumission"/>
          <input type="button" name="next" class="btn btn-success btn-md" value="Transmettre et finaliser" (click)="finaliseRegul($event)" [hidden]="declaration.typeDeclarationId != 1800" [disabled]="!isValid || isLoading" [title]="titreSoumission"/>
          <input type="button" name="save" class="btn btn-info btn-md" value="Accueil" [disabled]="isLoading" (click)="gotoAccueil($event)" />
        </div>
      </fieldset>
    </div>
  </div>
</form>
