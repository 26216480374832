import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild,  SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { Declaration } from 'src/app/entities/declaration/declaration.model';
import { FileUpload } from 'primeng/fileupload';


@Component({
    selector: 'dup-complement-declaration-form',
    templateUrl: './complementdeclarationform.component.html',
    styleUrls: [
      '../forms.css',
      './complementdeclarationform.css'
    ]
  })
  export class ComplementDeclarationFormComponent implements OnInit, OnChanges, OnDestroy {

    @Input() declaration: Declaration;
    @Input() rapportAlreadyUploaded: boolean;
    @Output() isComplementValid = new EventEmitter();
    @Output() fileDataUpload = new EventEmitter();


    @ViewChild('fileUploader') fileUploader: FileUpload;

    fileData:FormData;

    askToUpload: boolean = false;
    askNoFile: boolean = false;


    constructor() {
    }

    ngOnInit(): void {
      this.computeDefaultCheckBox();
    }

    computeDefaultCheckBox(){
      this.askToUpload = this.rapportAlreadyUploaded;
      this.askNoFile = this.declaration?.id != null && this.rapportAlreadyUploaded == false;
      this.isComplementValid.emit(this.askToUpload || this.askNoFile);
    }

    ngOnChanges(changes: SimpleChanges): void {
      for (let propName in changes) {  
        if(propName == 'rapportAlreadyUploaded') {
          this.computeDefaultCheckBox();
        }
      }
    }

    ngOnDestroy() {
    }


    onSelectUpload(event, uploader: FileUpload){
        if((event.files[0] as File).name.length > 80){
          uploader.remove(event, 0);
          this.askToUpload = false;
          this.isComplementValid.emit(false);
        }else{
            this.askNoFile = false;
            this.askToUpload = true;
        }
      }
  
      myUploader(event, uploader: FileUpload) {
        if(this.fileData) {
            uploader.remove(event, 0);
            this.askToUpload = false;
            this.isComplementValid.emit(false);
            this.fileDataUpload.emit(null);
        } else{
            this.fileData = new FormData();
            this.fileData.append("fichier",event.files[0] as File, (event.files[0] as File).name);
            this.fileData.append("declarationId",this.declaration.id.toString());
            this.fileData.append("nomFichier", (event.files[0] as File).name);
            (this.fileUploader as any).chooseIcon = "pi pi-check";
            this.isComplementValid.emit(true);
            this.fileDataUpload.emit(this.fileData);
        }
      }

      handleClick(checkbox){
        this.fileUploader.onBasicUploaderClick();
      }

      handleNoFileClick(uploader: FileUpload){
        this.askNoFile = true;
        this.askToUpload = false;
        this.fileData = null;
        this.fileUploader.clear();
        this.isComplementValid.emit(true);
        this.fileDataUpload.emit(null);
      }

      onCancelFileUpload(){
        this.askToUpload = false;
        this.isComplementValid.emit(false);
        this.fileDataUpload.emit(null);
      }



  }
