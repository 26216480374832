import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import * as $ from 'jquery';

import { Utilisateur } from 'src/app/entities/utilisateur/utilisateur.model';
import { Contact } from 'src/app/entities/contact/contact.model';
import { Adresse } from 'src/app/entities/adresse/adresse.model';
import { Region } from 'src/app/entities/region/region.model';
import { Departement } from 'src/app/entities/departement/departement.model';
import { Commune } from 'src/app/entities/commune/commune.model';
import { AdresseService } from 'src/app/entities/adresse/adresse.service';
import { ContactService } from 'src/app/entities/contact/contact.service';
import { CommuneService } from 'src/app/entities/commune/commune.service';
import { DepartementService } from 'src/app/entities/departement/departement.service';
import { RegionService } from 'src/app/entities/region/region.service';
import { AccountService } from 'src/app/shared/auth/account.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { LexStatutJuridique } from 'src/app/entities/lex-statut-juridique/lex-statut-juridique.model';
import { LexStatutJuridiqueService } from 'src/app/entities/lex-statut-juridique/lex-statut-juridique.service';
import { LexProfilUtilisateurService } from 'src/app/entities/lex-profil-utilisateur/lex-profil-utilisateur.service';
import { LexProfilUtilisateur } from 'src/app/entities/lex-profil-utilisateur/lex-profil-utilisateur.model';
import { ActeurService } from 'src/app/entities/acteur/acteur.service';
import { UtilisateurService } from 'src/app/entities/utilisateur/utilisateur.service';

@Component({
    selector: 'dup-settings',
    templateUrl: './settings.component.html',
    styleUrls: [
        '../forms.css'
    ]
})
export class SettingsComponent implements OnInit {

    settingsAccount: Utilisateur;
    contact: Contact;
    adresse: Adresse;
    region: Region;
    departement: Departement;
    commune: Commune;
    languages: any[];
    regions: Region[];
    departements: Departement[];
    communes: Commune[] = [];
    lexstatutjuridiques: LexStatutJuridique[];

    // utilisateur professionel
    isPro: Boolean = false;
    isAdmin: Boolean = false;
    isBSS: Boolean = false;
    isState: Boolean = false;
    isFrance: Boolean = true;
    accountExists: Boolean = false;

    communeLoading: Boolean = false;

    demandeLoading: boolean = false;
    demandeError: boolean = false;

    profilUtilisateurAskable:LexProfilUtilisateur[];
    profilToAsk:LexProfilUtilisateur;

    isValidSiret: boolean = false;


    constructor(
        private account: AccountService,
        private adresseService: AdresseService,
        private contactService: ContactService,
        private regionService: RegionService,
        private departementService: DepartementService,
        private communeService: CommuneService,
        private lexStatutJuridiqueService: LexStatutJuridiqueService,
        private router: Router,
        private principal: Principal,
        private lexProfilUtilisateurService: LexProfilUtilisateurService,
        private acteurService: ActeurService,
        private utilisateurService:UtilisateurService
    ) {
        this.contact = new Contact();
        this.adresse = new Adresse();
        this.adresse.pays = "France";
    }

    ngOnInit() {
        this.principal.identity().then((account) => {

            this.settingsAccount = account;

            if (account.id != null){
                this.accountExists = true;
            }

            if(this.settingsAccount.contactId != null) {
                this.contactService.find(account.contactId).subscribe((res) => {this.contact = res;})
            }

            if(this.settingsAccount.adresseId != null) {
                this.adresseService.find(account.adresseId).subscribe((res) => {
                    this.adresse = res;
                    if(!this.adresse.pays) {
                        this.changeToFrance();
                    }
                    if(this.adresse.pays.toLowerCase() != "france") this.isFrance = false;
                    if(this.adresse.communeInseeCom) {
                        this.communeService.find(this.adresse.communeInseeCom).subscribe((commune) => {
                        this.commune = commune;
                            this.regions.forEach((r) => {
                                if(r.inseeReg == this.commune.inseeReg) {
                                    this.region = r;
                                    this.onSelectRegion(null);
                                }
                            });
                        });
                    }
                })
            }
            this.principal.isAdmin().then((res) => {this.isAdmin = res;});
            this.principal.isBSS().then((res) => {this.isBSS = res;});
            this.principal.isState().then((res) => {this.isState = res;});
            if(account.statutJuridiqueId) {
                this.principal.isPro().then((res) => {this.isPro = res;})
            }
            this.validSiret();
        });
        this.regionService.query().subscribe((reg) => {
            this.regions = reg;
        });
        this.lexStatutJuridiqueService.query().subscribe((res) => {
            this.lexstatutjuridiques = res;
        });
        this.lexProfilUtilisateurService.query().subscribe((res) => {
            if (!this.accountExists){
                this.settingsAccount.profilUtilisateurLibelle = res.find(e => e.id == 4200)?.libelle;
                this.settingsAccount.profilUtilisateurId = 4200;
            }
            if(this.settingsAccount.profilUtilisateurId == 4200){
                var askableIds = [4250, 4500, 4400];
                this.profilUtilisateurAskable = res.filter(p=> p.id == 4450);
                this.profilUtilisateurAskable[0].libelle = "Mairie, Métropole";
                this.profilUtilisateurAskable = this.profilUtilisateurAskable.concat( res.filter(e => askableIds.includes(e.id)) .sort((a, b) => a.libelle.localeCompare(b.libelle)) );
                
            }
        });
    }

    computeStatutJuridiqueTitle(statutJuridique){
        if(statutJuridique.id == 3800){
            return "Professionnel / Collectivité / Organisme";
        }
        return statutJuridique.libelle;
    }

    findBySiret() {
        this.acteurService.getActeurBySiret(this.settingsAccount.siretEtablissement).subscribe((res:any) => {
            let lexstatutjuridique:LexStatutJuridique = _.find(this.lexstatutjuridiques,['code','2']);
            this.isPro = lexstatutjuridique.mnemo === 'PRO';
            let adresse:Adresse = new Adresse();
            this.settingsAccount.statutJuridiqueId = lexstatutjuridique.id;
            let result = res.results[0];
            this.settingsAccount.raisonSocialeEtab = result.nom_raison_sociale;

            adresse.nomVoie = result.siege.numero_voie + " " + result.siege.type_voie + " " + result.siege.libelle_voie;
            adresse.codePostal = result.siege.code_postal;
            adresse.pays = 'France';
            adresse.communeInseeCom = result.siege.commune;
            adresse.cedex = result.siege.cedex;
            adresse.complement = result.siege.complement_adresse;
            this.communeService.find(adresse.communeInseeCom).subscribe((commune) => {
                    adresse.commune = commune;
                    this.settingsAccount.adresse = adresse;
                    this.adresse = adresse;
                    this.commune = commune;
                    this.region = this.regions.find(r => r.inseeReg == commune.inseeReg );
                    this.onSelectRegion(null);  
                });
        });
    }

    public onSelectRegion(event) {
        if(this.region) {
            this.departementService.findByRegion(this.region.inseeReg).subscribe((dep) => {
                this.departements = dep;
                if(event == null) {
                    this.departements.forEach((d) => {
                        if(d.inseeDep == this.commune.inseeDep) {
                            this.departement = d;
                            this.onSelectDepartement(null);
                        }
                    });
                }
            });
        }
    }

    public onSelectDepartement(event) {
        if(this.departement) {
            this.communeLoading = true;
            this.communeService.findByDepartement(this.departement.inseeDep).subscribe((com) => {
                this.communes = com;
                this.communeLoading = false;
            });
        }
    }

    findCity(event) {
        this.communeService.findByCp(this.adresse.codePostal).subscribe((communes) =>
        {
          this.communes = communes;
          if(this.communes.length == 1)
          {
            this.commune = this.communes[0];
            this.adresse.commune = this.commune;
            this.adresse.communeInseeCom = this.commune.inseeCom;
          }

          setTimeout(() => {
            this.regions.forEach((r) => {
              if(r.inseeReg == this.communes[0].inseeReg) {
                  this.region = r;
                  this.departementService.findByRegion(this.region.inseeReg).subscribe((dep) => {
                      this.departements = dep;
                      this.departements.forEach((d) => {
                        if(d.inseeDep == this.communes[0].inseeDep) {
                            this.departement = d;
                        }
                    });
                  });
              }
            });
          }, 500);
        });
      }

    public onSelectCommune(event) {
        if(this.commune) {
            this.adresse.communeInseeCom = this.commune.inseeCom;
        }
    }

    save() {

        if(!this.accountExists && this.settingsAccount.email == null){
            return;
        }

        if(!this.adresse.id) {
            this.adresseService.create(this.adresse).subscribe(
                (savedAdresse: Adresse) => {
                this.onSaveAdresseSuccess(savedAdresse);
                }
                , (res: any) => this.onSaveError(res));
        } else {
            this.adresseService.update(this.adresse).subscribe((savedAdresse: Adresse) => {
                this.onSaveAdresseSuccess(savedAdresse);
                }
                , (res: any) => this.onSaveError(res));
        }
    }

    private onSaveAdresseSuccess(savedAdresse: Adresse) {
      if(savedAdresse) {
        this.adresse = savedAdresse;
        this.settingsAccount.adresseId = savedAdresse.id;
      }

      if(this.contact && this.contact.nom) {
        if(!this.contact.id) {
            this.contactService.create(this.contact).subscribe(
                (savedContact: Contact) => this.onSaveContactSuccess(savedContact)
                , (res: any) => this.onSaveError(res));
        } else {
            this.contactService.update(this.contact).subscribe(
                (savedContact: Contact) => this.onSaveContactSuccess(savedContact)
                , (res: any) => this.onSaveError(res));
        }
      } else {
        this.onSaveContactSuccess(null);
      }
    }

    private onSaveContactSuccess(savedContact: Contact) {
      if(savedContact) {
        this.contact = savedContact;
        this.settingsAccount.contactId = savedContact.id;
      }
        this.account.save(this.settingsAccount).subscribe(() => {
            this.principal.identity(true).then((account) => {
                this.settingsAccount = account;
                this.principal.completeAccount();
                this.router.navigate(['']);
            });
        }, (res: any) => {
            this.onSaveError(res)
        });
    }

    private onSaveError(res?: any) {
        if (res) {
          console.log(res);
        }
      }

    public selectCiv(event) {
        this.contact.civilite = event.target.value;
    }

    changeToFrance() {
        this.adresse.pays = "France";
    }

    changeStatut(statutJuridique:LexStatutJuridique) {
        this.settingsAccount.statutJuridiqueId = statutJuridique.id;
        this.settingsAccount.statutJuridiqueLibelle = statutJuridique.libelle;
        this.isPro = statutJuridique.mnemo === 'PRO';
    }

    cancel() {
        this.router.navigate(['']);
    }

    sendDemandeProfil(){
        this.demandeLoading = true;
        this.settingsAccount.dateDemandeProfil = new Date();
        this.settingsAccount.demandeProfilUtilisateurId = this.profilToAsk.id;
        this.settingsAccount.demandeProfilUtilisateurLibelle = this.profilToAsk.libelle;
        this.utilisateurService.sendDemandeProfil(this.profilToAsk.id).subscribe(
            res => { 
                this.demandeError = res == "true";
                this.demandeLoading = false;
            },
            err=> {
                this.demandeLoading = false;
                this.demandeError = true;
            }
        );
    }

    validSiret(){
        if(this.settingsAccount && this.settingsAccount != undefined){
          const regex = new RegExp('^[\\0-9]{14}$', 'g');
                                    
          this.isValidSiret = this.settingsAccount.siretEtablissement.match(regex) != null
          return this.isValidSiret;
          }
          return false;
    
      }
}
